import { Content, Title } from "../layout";
import styled from "styled-components";
import { useState } from "react";
import FlipCard from "../components/FlipCard";
import { projects } from "../lib/projects";

const ColumnContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  padding: 2em;
`;

const PaddedContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const OptionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: -2em;
  z-index: 1;
  padding-top: 1em;
  padding-bottom: 2em;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 50%,
    #ffffff00 100%
  );

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MenuItem = styled.div`
  font-size: 1.8em;
  color: ${({ selected }) => (selected ? "#000000" : "#cccccc")};
  cursor: pointer;
  transition: color 0.15s ease-in, font-size 0.5s ease-in;

  :hover {
    color: ${({ selected }) => (selected ? "#000000" : "#777777")};
  }

  @media only screen and (max-width: 870px) {
    font-size: 1.3em;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  flex: 1;
`;

const Projects = () => {
  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <ColumnContent>
      <Title>
        my<strong>projects</strong>
      </Title>
      <Content>hover/press on any project to view details.</Content>
      <OptionsRow>
        <MenuItem
          selected={selectedItem === 0}
          onClick={() => setSelectedItem(0)}
        >
          <strong>individual</strong>projects
        </MenuItem>
        <MenuItem
          selected={selectedItem === 1}
          onClick={() => setSelectedItem(1)}
        >
          work<strong>experience</strong>
        </MenuItem>
        <MenuItem
          selected={selectedItem === 2}
          onClick={() => setSelectedItem(2)}
        >
          other<strong>work</strong>
        </MenuItem>
      </OptionsRow>
      <PaddedContent>
        {selectedItem === 0 && (
          <>
            <div>projects designed and developed fully by myself.</div>
            <Grid>
              {projects.individual.map((p) => (
                <FlipCard project={p} key={p.name} />
              ))}
            </Grid>
          </>
        )}

        {selectedItem === 1 && (
          <>
            <div>workplace related projects.</div>
            <Grid>
              {projects.work.map((p) => (
                <FlipCard project={p} key={p.name} />
              ))}
            </Grid>
          </>
        )}

        {selectedItem === 2 && (
          <>
            <div>
              projects I have worked on in teams, but which are not
              workplace-related.
            </div>
            <Grid>
              {projects.team.map((p) => (
                <FlipCard project={p} key={p.name} />
              ))}
            </Grid>
          </>
        )}
      </PaddedContent>
    </ColumnContent>
  );
};

export default Projects;
