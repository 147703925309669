import axios from "axios";

const MAIL_URL = "https://sb26.io/sendmail/index.php";

export const sendMail = async (name, email, message) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        MAIL_URL,
        { name, message: "From: " + email + "\n\n" + message },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
