import {
  Content,
  Link,
  PaddedContent,
  Separator,
  Small,
  Subtitle,
  Title,
} from "../layout";

const About = () => {
  return (
    <PaddedContent>
      <Title>
        about<strong>me</strong>
      </Title>
      <Subtitle>
        short<strong>bio</strong>
      </Subtitle>
      <Content>
        My name is Sorin Banica and I am a full-stack developer. I worked on
        frontend and backend, in large teams and alone, for startups and for
        established companies.
      </Content>
      <Content>
        To learn more about my work experience, go to <strong>Projects</strong>{" "}
        or{" "}
        <Link href="https://www.linkedin.com/in/sorinbanica/" target="_blank">
          check out my linkedin profile
        </Link>
        .
      </Content>
      <Subtitle>
        my<strong>expertise</strong>
      </Subtitle>
      <Content>
        Worked mainly on web & mobile applications. Among others, I developed
        APIs, OTA update systems for devices, real-time tracking systems,
        notification services. I worked on system architectures, database design
        and optimization. Also, I did the UX design and frontend implementation
        for a large part of the projects I worked on.
      </Content>
      <Subtitle>links</Subtitle>
      <Separator />
      <Link href="https://www.linkedin.com/in/sorinbanica/" target="_blank">
        linkedin
      </Link>
      <Separator size={4} />
      <Link href="https://www.github.com/sxnb/" target="_blank">
        github
      </Link>
      <Separator size={40} />
      <Small>
        This is a mobile-first website created in React 18. It uses
        styled-components and some custom CSS.
      </Small>
    </PaddedContent>
  );
};

export default About;
