export const projects = {
  individual: [
    {
      name: "VisualInvoicer",
      description: (
        <>
          <div style={{ marginBottom: 16 }}>
            <a href="https://www.visualinvoicer.com" target="_blank">
              www.visualinvoicer.com
            </a>
          </div>
          <div>
            VisualInvoicer is an online tool that allows users to easily
            generate invoices. The application is implemented in React, while
            the backend stack includes TypeScript, Firebase and an integration
            with Paddle for payments. The application has an automatic
            deployment process with Vercel, and quite a few integrations with
            monitoring tools.
          </div>
        </>
      ),
      technologies: [
        "React",
        "Typescript",
        "Node.js",
        "express",
        "prisma",
        "MySQL",
        "Redis",
        "Vercel",
        "Paddle",
        "Firebase",
      ],
      workedOn: [
        "architecture",
        "UX",
        "backend",
        "frontend",
        "QA",
        "deployment",
      ],
    },
    {
      name: "planameetup.com",
      description: (
        <div>
          <div style={{ marginBottom: 16 }}>
            <a href="https://www.planameetup.com" target="_blank">
              www.planameetup.com
            </a>
          </div>
          <div>
            A website where people can easily plan events. Created in the course
            of a few days.
          </div>
        </div>
      ),
      technologies: [
        "React",
        "Typescript",
        "Node.js",
        "Sass",
        "MongoDB",
        "Mongo Atlas",
        "Google Cloud Platform",
      ],
      workedOn: [
        "architecture",
        "UX",
        "backend",
        "frontend",
        "testing",
        "deployment",
      ],
    },

    {
      name: "Radiotal",
      description:
        "Single handedly launched an app that allows users to listen to radio stations from all around the world. The mobile app is done in React Native, while the backend stack includes TypeScript and Firebase. The ecosystem includes some internal tools that are done in React.",
      technologies: [
        "React",
        "React Native",
        "Typescript",
        "Node.js",
        "express",
        "prisma",
        "MySQL",
        "Redis",
        "DigitalOcean",
      ],
      workedOn: [
        "architecture",
        "UX",
        "backend",
        "frontend",
        "QA",
        "deployment",
      ],
      more: "radiotal",
    },
    {
      name: "Colorcode design",
      description:
        "A collection of components and design tools, suitable for web and mobile applications. I try to use these components in every personal project that I work on.",
      technologies: ["React", "SCSS", "Typescript"],
      workedOn: ["UX", "frontend", "deployment"],
      more: "colorcode-design",
    },
    {
      name: "Codedraft",
      description:
        "Codedraft is a mobile app allows you to learn CSS at your own pace. Start with basics, then move to more advanced concepts. Codedraft divides the courses into small bits of information so that you can study at your own pace. Occasionally you will get questions about what you have just studied.",
      technologies: [
        "React",
        "React Native",
        "Typescript",
        "Node.js",
        "express",
        "Redis",
        "DigitalOcean",
      ],
      workedOn: [
        "architecture",
        "UX",
        "backend",
        "frontend",
        "QA",
        "deployment",
      ],
      more: "codedraft",
    },
    {
      name: "Planorio",
      description:
        "Planorio is an application which connects booking-based businesses with their customers. The system allows the customers to easily book various services, while allowing the businesses to interact with their customers in a very simple manner.",
      technologies: [
        "Angular",
        "Typescript",
        "Bootstrap",
        "fabricjs",
        "Node.js",
        "express",
        "sequelize",
        "MySQL",
        "Redis",
        "RabbitMQ",
        "AWS EC2",
        "AWS RDS",
        "Route53",
        "GCM",
        "Jenkins",
      ],
      workedOn: [
        "architecture",
        "UX",
        "backend",
        "frontend",
        "QA",
        "deployment",
      ],
      more: "planorio",
    },
    {
      name: "RockyDB",
      description:
        "An in-memory storage engine which allows fast&easy storage of various data structures. ",
      technologies: ["Kotlin", "Javascript"],
      workedOn: ["architecture", "implementation", "testing"],
      more: "rockydb",
    },
    {
      name: "ApiVal",
      description:
        "A tool which allows users to create automatic API tests using a web interface.",
      technologies: [
        "Angular",
        "Typescript",
        "Node.js",
        "Bootstrap",
        "HTML5",
        "Sass",
      ],
      workedOn: ["architecture", "UX", "backend", "frontend", "deployment"],
      more: "apival",
    },
    {
      name: "Soundresidence",
      description:
        "A mobile&web application which allows users to share songs.",
      technologies: [
        "Angular",
        "Typescript",
        "Ionic Framework",
        "PHP",
        "Node.js",
        "Symfony",
        "Doctrine",
        "MySQL",
        "RabbitMQ",
        "Firebase",
        "AWS",
        "Jenkins",
      ],
      workedOn: ["architecture", "UX", "backend", "frontend", "deployment"],
      more: "soundresidence",
    },
    {
      name: "ERPFirst",
      description:
        "A cloud-based application which allows companies to manage and plan their business resources and processes.",
      technologies: [
        "AngularJS",
        "Javascript",
        "MetroUI",
        "PHP",
        "MySQL",
        "Redis",
        "AWS",
      ],
      workedOn: [
        "architecture",
        "UX",
        "backend",
        "frontend",
        "QA",
        "deployment",
      ],
    },
    {
      name: "ABill",
      description:
        "A web application which allows users to handle their invoices.",
      technologies: [
        "AngularJS",
        "Javascript",
        "Bootstrap",
        "PHP",
        "MySQL",
        "Redis",
        "AWS",
      ],
      workedOn: [
        "architecture",
        "UX",
        "backend",
        "frontend",
        "QA",
        "deployment",
      ],
    },
  ],
  work: [
    {
      name: "Senior Full-stack Engineer @ StreamYard",
      description:
        "Working as a full stack engineer in the Growth team, implementing features and running experiments all across the StreamYard platform. Worked with React on the frontend side and Node.js on the backend.",
      technologies: [
        "React",
        "Node.js",
        "Google Cloud Platform",
        "LaunchDarkly for A/B tests",
      ],
      workedOn: ["backend", "frontend"],
    },
    {
      name: "Senior Full-stack Engineer @ NearForm",
      description:
        "Worked as a full stack engineer, with React on the frontend side and Node.js with PostgreSQL on the backend.",
      technologies: ["React", "Node.js", "PostgreSQL", "React Testing Library"],
      workedOn: ["backend", "frontend"],
    },
    {
      name: "Software Engineer @ Spotify",
      description:
        "Worked on both frontend & backend sides of the checkout, sometimes on other sides of the web ecosystem, occasionally helping with technical interviewing of potential employees.",
      technologies: [
        "React",
        "TypeScript",
        "Node.js",
        "Java/Spring",
        "SCSS",
        "PHP",
        "React Testing Library",
      ],
      workedOn: ["backend", "frontend"],
    },
    {
      name: "Senior Software Engineer @ Bitdefender",
      description:
        "Worked on both frontend and backend sides of GravityZone, an application which provides enterprise security for multiple environments.",
      technologies: [
        "Javascript",
        "Ext.js",
        "PHP",
        "MongoDB",
        "Redis",
        "Hadoop",
        "Mocha",
        "Chai",
      ],
      workedOn: ["backend", "frontend"],
    },
    {
      name: "Software Engineer @ Colorcode",
      description:
        "Worked on a web application that allows doctors to keep track of patients and surgeries done using a specific LASIK laser.",
      technologies: [
        "AngularJS",
        "Bootstrap",
        "Threejs",
        "C#",
        "SQLite",
        "Azure",
      ],
      workedOn: ["architecture", "frontend", "backend", "deployment"],
    },
    {
      name: "Backend Engineer @ Lock8",
      description:
        "Designed and worked on the backend of a bike fleet management system. Also, done some embedded programming on the device and some frontend work.",
      technologies: [
        "Javascript",
        "jQuery",
        "PHP",
        "PostgreSQL",
        "Redis",
        "C",
        "AWS",
      ],
      workedOn: ["architecture", "frontend", "backend", "deployment"],
    },
    {
      name: "Backend Engineer @ 4PSA - Hubgets",
      description:
        "Worked on the backend side of Hubgets, a communication application.",
      technologies: [
        "PHP",
        "MySQL",
        "Elasticsearch",
        "Redis",
        "Selenium",
        "PHPUnit",
      ],
      workedOn: ["backend"],
    },
  ],
  team: [
    {
      name: "Google Hash Code Finalist, 2016",
      description:
        "Over 15000 participants attended the online qualification phase - the first 50 teams (consisting of 2-4 participants) qualified to the final phase in Paris. " +
        "My team qualified and obtained the 20th place in the final phase.",
      workedOn: ["algorithm implementation"],
      technologies: ["Java"],
    },
    {
      name: "Strategio",
      description:
        "An in-browser, real-time, multiplayer strategy game developed with socket.io and three.js.",
      workedOn: ["architecture", "frontend", "backend", "deployment"],
      technologies: [
        "Javascript",
        "three.js",
        "Typescript",
        "socket.io",
        "heroku",
      ],
    },
    {
      name: "North",
      description: "A simple geography game developed in Ionic Framework.",
      workedOn: ["architecture", "UX", "frontend", "deployment"],
      technologies: ["Angular", "Bootstrap", "Ionic Framework"],
      more: "north",
    },
  ],
};

export const detailedProjects = {
  radiotal: {
    name: "Radiotal",
    thumbnail: "assets/imgs/radiotal/logo.jpeg",
    short:
      "Radiotal is a mobile app that allows users to listen to radio stations from all around the world.",
    long: "The mobile app is done in React Native, while the backend stack includes TypeScript and Firebase. The ecosystem includes some internal tools that are done in React. Currently, a web application is also in development.",
    isOffline: true,
    technologies: [
      "React",
      "React Native",
      "Typescript",
      "Node.js",
      "express",
      "prisma",
      "MySQL",
      "Redis",
      "DigitalOcean",
    ],
    workedOn: ["architecture", "UX", "backend", "frontend", "QA", "deployment"],
  },

  codedraft: {
    name: "Codedraft",
    url: "http://www.codedraft.com",
    thumbnail: "assets/imgs/codedraft/logo.jpeg",
    short:
      "Codedraft is a mobile app allows you to learn CSS at your own pace. Start with basics, then move to more advanced concepts.",
    long: "Codedraft divides the courses into small bits of information so that you can study at your own pace. Occasionally you will get questions about what you have just studied.",
    html: 'Website: <a href="https://www.codedraft.io" target="_blank">www.codedraft.io</a><br />Google Play link: <a href="https://play.google.com/store/apps/details?id=com.radiotal" target="_blank">click here</a>',
    isOffline: true,
    technologies: [
      "React",
      "React Native",
      "Typescript",
      "Node.js",
      "express",
      "Redis",
      "DigitalOcean",
    ],
    workedOn: ["architecture", "UX", "backend", "frontend", "QA", "deployment"],
  },

  "colorcode-design": {
    name: "Colorcode•Design",
    url: "https://www.colorcode.design",
    thumbnail: "assets/imgs/colorcode-design/logo.jpeg",
    short:
      "A collection of components and design tools, suitable for web and mobile applications. I try to use these components in every personal project that I work on.",
    long: "Currently, a web application is also in development.",
    html: 'Website: <a href="https://www.colorcode.design" target="_blank">www.colorcode.design</a>',
    technologies: ["React", "SCSS", "Typescript"],
    workedOn: ["UX", "frontend", "deployment"],
  },

  planorio: {
    name: "Planorio",
    url: "http://www.planorio.com",
    thumbnail: "assets/imgs/planorio/logo.png",
    short:
      "Planorio is an application which connects booking-based businesses with their customers. The system allows the customers to easily book various services, while allowing the businesses to interact with their customers in a very simple manner.",
    long:
      "The main purpose of Planorio is to give people a complete service-booking tool. It will replace the old-fashioned bookings by phone, by allowing people to book a service and receive confirmation, on their phones, with just a few taps.\n" +
      "\n" +
      "On the company side of the application, Planorio will provide a suite of powerful tools which will allow the companies to stay in touch with the customers and with their booking history. Additionally, it will allow the administrators to create complex reports based on various metrics.",
    isOffline: true,
    technologies: [
      "Angular",
      "Typescript",
      "Bootstrap",
      "fabricjs",
      "Node.js",
      "express",
      "sequelize",
      "MySQL",
      "Redis",
      "RabbitMQ",
      "AWS EC2",
      "AWS RDS",
      "Route53",
      "GCM",
      "Jenkins",
    ],
    workedOn: ["architecture", "UX", "backend", "frontend", "QA", "deployment"],
    images: [
      {
        small: "assets/imgs/planorio/0-sm.png",
        medium: "assets/imgs/planorio/0-sm-sm.png",
        big: "assets/imgs/planorio/0.png",
      },
      {
        small: "assets/imgs/planorio/1-sm.png",
        medium: "assets/imgs/planorio/1-sm.png",
        big: "assets/imgs/planorio/1.png",
      },
      {
        small: "assets/imgs/planorio/2-sm.png",
        medium: "assets/imgs/planorio/2-sm.png",
        big: "assets/imgs/planorio/2.png",
      },
      {
        small: "assets/imgs/planorio/3-sm.png",
        medium: "assets/imgs/planorio/3-sm.png",
        big: "assets/imgs/planorio/3.png",
      },
      {
        small: "assets/imgs/planorio/4-sm.png",
        medium: "assets/imgs/planorio/4-sm.png",
        big: "assets/imgs/planorio/4.png",
      },
      {
        small: "assets/imgs/planorio/5-sm.png",
        medium: "assets/imgs/planorio/5-sm.png",
        big: "assets/imgs/planorio/5.png",
      },
      {
        small: "assets/imgs/planorio/6-sm.png",
        medium: "assets/imgs/planorio/6-sm.png",
        big: "assets/imgs/planorio/6.png",
      },
      {
        small: "assets/imgs/planorio/7-sm.png",
        medium: "assets/imgs/planorio/7-sm.png",
        big: "assets/imgs/planorio/7.png",
      },
      {
        small: "assets/imgs/planorio/8-sm.png",
        medium: "assets/imgs/planorio/8-sm.png",
        big: "assets/imgs/planorio/8.png",
      },
      {
        small: "assets/imgs/planorio/9-sm.png",
        medium: "assets/imgs/planorio/9-sm.png",
        big: "assets/imgs/planorio/9.png",
      },
    ],
  },
  rockydb: {
    name: "RockyDB",
    url: "https://github.com/sxnb/RockyDB/",
    thumbnail: "http://colorcode.me/assets/rockydb.png",
    short:
      "RockyDB is an in-memory storage engine which allows fast&easy storage of various data structures.",
    long:
      "RockyDB is an in-memory storage engine which allows fast&easy storage of various data structures. At the moment, RockyDB can store primitive data types, as well as Lists, Queues and Stacks." +
      "\n\n" +
      "Additionally, it has durability by making smart automatic dumps to file, it has a key subscription mechanism and it allows performing of asynchronous operations. \n\n" +
      "At this moment, a Javascript connector is available. However, implementation of new connectors is easy, as the entire communication is done via sockets.\n\n" +
      "It was developed in Kotlin over a period of a few days, with the purpose of learning.",
    html: 'RockyDB Server: <a href="https://github.com/sxnb/RockyDB-JS-connector" target="_blank">https://github.com/sxnb/RockyDB-JS-connector</a><br />GitHub repo: <a href="https://github.com/sxnb/RockyDB" target="_blank">https://github.com/sxnb/RockyDB</a><br />JS Connector: <a href="https://github.com/sxnb/RockyDB-JS-connector" target="_blank">https://github.com/sxnb/RockyDB-JS-connector</a><br />',
    technologies: ["Kotlin", "Javascript"],
    workedOn: ["architecture", "implementation"],
    images: [],
  },
  apival: {
    name: "ApiVal",
    url: "http://apival.colorcode.me/editor",
    thumbnail: "",
    short:
      "ApiVal tool which allows users to create automatic API tests using a web interface.",
    long:
      "It allows users to generate flows of requests and vaildations, also being capable to generate dummy resources automatically. The tests are then exported to a JSON file which is used by the ApiVal command-line client." +
      "\n" +
      "The project consists of the following components - the Visual Editor, a web application developed in Angular 6, and a command-line client, which is used to run the tests and produce an output.\n",
    html: 'GitHub page: <a href="https://sxnb.github.io/apival" target="_blank">sxnb.github.io/apival</a><br />npm client package: <a href="https://www.npmjs.com/package/apival-cli" target="_blank">https://www.npmjs.com/package/apival-cli</a>',
    technologies: [
      "Angular",
      "Typescript",
      "Ionic Framework",
      "PHP",
      "Node.js",
      "Symfony",
      "Doctrine",
      "MySQL",
      "RabbitMQ",
      "Firebase",
      "AWS",
      "Jenkins",
    ],
    workedOn: ["architecture", "UX", "backend", "frontend", "deployment"],
    images: [
      {
        small: "assets/imgs/apival/1-sm.png",
        medium: "assets/imgs/apival/1-sm.png",
        big: "assets/imgs/apival/1.png",
      },
      {
        small: "assets/imgs/apival/2-sm.png",
        medium: "assets/imgs/apival/2-sm.png",
        big: "assets/imgs/apival/2.png",
      },
      {
        small: "assets/imgs/apival/3-sm.png",
        medium: "assets/imgs/apival/3-sm.png",
        big: "assets/imgs/apival/3.png",
      },
      {
        small: "assets/imgs/apival/4-sm.png",
        medium: "assets/imgs/apival/4-sm.png",
        big: "assets/imgs/apival/4.png",
      },
    ],
  },
  soundresidence: {
    name: "Soundresidence",
    url: "http://beta.soundresidence.com",
    thumbnail: "assets/imgs/soundresidence/logo.png",
    short:
      "Soundresidence is mobile & web-based social network which allows users to share their moods using songs.",
    long:
      "It allows users to connect with other users, follow their posts and interact with each other using music as a mean of expressing themselves." +
      "\n" +
      "The project consists of the following components - a web application developed in Angular 6, a mobile application developed in Ionic Framework and a backend developed in Symfony, NodeJS, MySQL and Redis. \n\n" +
      "The backend is deployed on AWS, while the frontend is deployed on FTP using a Jenkins job." +
      "\n\n" +
      "The main purpose of this project was to develop an idea I've had for a long time, using some technologies I haven't used too much before - namely Symfony and Jenkins. It also allowed me to get a better grasp of the process of getting a mobile app on Google Play Store. \n",
    html: 'Website: <a href="http://beta.soundresidence.com" target="_blank">beta.soundresidence.com</a><br />Google Play link: <a href="https://play.google.com/store/apps/details?id=com.colorcode.soundresidenceapp" target="_blank">click here</a>',
    isOffline: true,
    technologies: [
      "Angular",
      "Typescript",
      "Ionic Framework",
      "PHP",
      "Node.js",
      "Symfony",
      "Doctrine",
      "MySQL",
      "RabbitMQ",
      "Firebase",
      "AWS",
      "Jenkins",
    ],
    workedOn: ["architecture", "UX", "backend", "frontend", "deployment"],
    images: [
      {
        small: "assets/imgs/soundresidence/1-sm.png",
        medium: "assets/imgs/soundresidence/1-sm.png",
        big: "assets/imgs/soundresidence/1.png",
      },
      {
        small: "assets/imgs/soundresidence/2-sm.png",
        medium: "assets/imgs/soundresidence/2-sm.png",
        big: "assets/imgs/soundresidence/2.png",
      },
      {
        small: "assets/imgs/soundresidence/3-sm.png",
        medium: "assets/imgs/soundresidence/3-sm.png",
        big: "assets/imgs/soundresidence/3.png",
      },
      {
        small: "assets/imgs/soundresidence/4-sm.png",
        medium: "assets/imgs/soundresidence/4-sm.png",
        big: "assets/imgs/soundresidence/4.png",
      },
      {
        small: "assets/imgs/soundresidence/5-sm.png",
        medium: "assets/imgs/soundresidence/5-sm.png",
        big: "assets/imgs/soundresidence/5.png",
      },
      {
        small: "assets/imgs/soundresidence/6-sm.png",
        medium: "assets/imgs/soundresidence/6-sm.png",
        big: "assets/imgs/soundresidence/6.png",
      },
      {
        small: "assets/imgs/soundresidence/7-sm.png",
        medium: "assets/imgs/soundresidence/7-sm.png",
        big: "assets/imgs/soundresidence/7.png",
      },
      {
        small: "assets/imgs/soundresidence/8-sm.png",
        medium: "assets/imgs/soundresidence/8-sm.png",
        big: "assets/imgs/soundresidence/8.png",
      },
      {
        small: "assets/imgs/soundresidence/9-sm.png",
        medium: "assets/imgs/soundresidence/9-sm.png",
        big: "assets/imgs/soundresidence/9.png",
      },
      {
        small: "assets/imgs/soundresidence/10-sm.png",
        medium: "assets/imgs/soundresidence/10-sm.png",
        big: "assets/imgs/soundresidence/10.png",
      },
      {
        small: "assets/imgs/soundresidence/11-sm.png",
        medium: "assets/imgs/soundresidence/11-sm.png",
        big: "assets/imgs/soundresidence/11.png",
      },
      {
        small: "assets/imgs/soundresidence/12-sm.png",
        medium: "assets/imgs/soundresidence/12-sm.png",
        big: "assets/imgs/soundresidence/12.png",
      },
    ],
  },
  north: {
    name: "North",
    url: "",
    thumbnail: "assets/imgs/north/icon.png",
    short:
      "North is a simple geography game where you get a list of cities and have to order them from north to south in under 30 seconds.",
    long:
      "You can use the map, provided as hint, in order to see the location for certain cities.\n\n" +
      "The game has 99 levels and the difficulty increases after each level. Depending on the speed of solving, a number of stars is received as reward. The stars are then used at unlocking new levels. When in difficulty (or when curious), use the hint page to quickly look up the cities on Wikipedia.\n\n" +
      "The project was created in a two-day hackaton and its main purposes was to create a complete application and have a full interaction with the Google Play Store.",
    // html: 'Google Play link: <a href="https://play.google.com/store/apps/details?id=io.colorcode.north.free" target="_blank">click here</a>',
    workedOn: ["architecture", "UX", "frontend", "deployment"],
    technologies: ["Angular", "Bootstrap", "Ionic Framework"],
    isOffline: true,
    images: [
      {
        small: "assets/imgs/north/1-sm.png",
        medium: "assets/imgs/north/1-sm.png",
        big: "assets/imgs/north/1.png",
      },
      {
        small: "assets/imgs/north/2-sm.png",
        medium: "assets/imgs/north/2-sm.png",
        big: "assets/imgs/north/2.png",
      },
      {
        small: "assets/imgs/north/3-sm.png",
        medium: "assets/imgs/north/3-sm.png",
        big: "assets/imgs/north/3.png",
      },
      {
        small: "assets/imgs/north/4-sm.png",
        medium: "assets/imgs/north/4-sm.png",
        big: "assets/imgs/north/4.png",
      },
      {
        small: "assets/imgs/north/5-sm.png",
        medium: "assets/imgs/north/5-sm.png",
        big: "assets/imgs/north/5.png",
      },
      {
        small: "assets/imgs/north/6-sm.png",
        medium: "assets/imgs/north/6-sm.png",
        big: "assets/imgs/north/6.png",
      },
    ],
  },
};
