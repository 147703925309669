import "./App.scss";
import styled from "styled-components";
import { useEffect, useState } from "react";
import About from "./screens/About";
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Projects from "./screens/Projects";
import ProjectInfo from "./screens/ProjectInfo";
import Contact from "./screens/Contact";

const Card = styled.div`
  position: absolute;
  height: 90%;
  width: 90%;
  left: 50%;
  top: 50%;
  box-shadow: 5px 5px 44px -5px rgb(0 0 0 / 75%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear;
  display: flex;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 1000px) {
    height: 100%;
    width: 100%;
    overflow: auto;
  }
`;

const Menu = styled.div`
  flex: 4;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 2em;
  display: flex;
  flex-direction: column;
  max-width: 33%;
  transition: background-color 0.5s ease-in;

  @media only screen and (max-width: 576px) {
    flex-direction: row;
    max-width: 100%;
    flex: 0;
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 1em;
  }
`;

const MenuWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  transition: background-color 0.5s ease-in;
  
  @media only screen and (max-width: 576px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const LogoWrapper = styled.div`
  flex: 0;
  text-align: center;
  color: #ffffff;
  font-size: 48px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;

  @media only screen and (max-width: 576px) {
    display: none;
  }

`;

const MenuItem = styled.div`
  font-size: 1.8em;
  margin: 1em 1em 0 1em;
  font-weight: ${({ selected }) => (selected ? "bold" : "regular")};
  color: #ffffff;
  cursor: pointer;
  transition: all 0.15s ease-in;

  :hover {
    text-shadow: 0 0 4px #000000;
  }

  @media only screen and (max-width: 576px) {
    margin-top: 0;
    font-size: 1.3em;
  }
`;

const Content = styled.div`
  flex: 8;
  background-color: #ffffff;
`;

const backgrounds = {
  about: "#ee5253",
  projects: "#10ac84",
  project: "#10ac84",
  contact: "#273c75",
};

function App() {
  const [selectedItem, setSelectedItem] = useState("");

  const location = useLocation();

  useEffect(() => {
    setSelectedItem((location.pathname || "/about").split("/")[1] || "about");
  }, [location.pathname]);

  return (
    <div className="app">
      <Card className="main-card">
        <Menu backgroundColor={backgrounds[selectedItem]}>
          <MenuWrapper>
            <Link to="/">
              <MenuItem selected={selectedItem === "about"}>about</MenuItem>
            </Link>
            <Link to="/projects">
              <MenuItem
                onClick={() => setSelectedItem("projects")}
                selected={
                  selectedItem === "projects" || selectedItem === "project"
                }
              >
                projects
              </MenuItem>
            </Link>
            <Link to="/contact">
              <MenuItem
                onClick={() => setSelectedItem("contact")}
                selected={selectedItem === "contact"}
              >
                contact
              </MenuItem>
            </Link>
          </MenuWrapper>
          <LogoWrapper>sb</LogoWrapper>
        </Menu>
        <Content>
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/project/:id" element={<ProjectInfo />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Content>
      </Card>
    </div>
  );
}

export default App;
