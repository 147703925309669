import { PaddedContent, Separator, Title } from "../layout";
import styled from "styled-components";
import { useState } from "react";
import { PopupButton, Widget } from "@typeform/embed-react";
import { sendMail } from "../lib/contact";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Col1 = styled.div`
  flex: 2;
  max-width: 16.66%;
  margin: auto 0;
`;

const Col2 = styled.div`
  flex: 10;
  max-width: 83.33%;
`;

const Contact = () => {
  const [message, setMessage] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const data = {};
    const formData = new FormData(e.currentTarget);
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }

    if (!data.email.trim()) {
      return alert("Please enter a valid email.");
    }

    if (!data.name.trim()) {
      return alert("Please enter a valid name.");
    }

    if (!data.message.trim()) {
      return alert("Please enter a valid message.");
    }

    sendMail(data.name, data.email, data.message)
      .then(() => {
        setMessage(
          "Your email was successfully sent. Thank you and I will get in touch as soon as possible."
        );
      })
      .catch((e) => {
        setMessage(
          "An error occurred while sending the email - most probably the server is unreachable. Please use one of the Linkedin or Email links from the About section."
        );
      });
  };

  return (
    <PaddedContent style={{ fontWeight: 200 }}>
      <Title>contact</Title>

      <Separator />

      {!message && (
        <div>
          best way to get in touch is to add me on{" "}
          <a
            href="https://www.linkedin.com/in/sorin-banica-22a95351/"
            target="_blank"
          >
            linkedin
          </a>
          . however, if it is simpler for you, you can send me a direct email by
          using the TypeForm button below.
          <Separator size={40} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PopupButton id="mHULYpWA" style={{ fontSize: 20, whiteSpace: 'break-spaces' }}>
              click here to open the TypeForm window
            </PopupButton>
          </div>
          {/*
          <form onSubmit={onSubmit}>
            <Row>
              <Col1>name</Col1>
              <Col2>
                <input type="text" name="name" />
              </Col2>
            </Row>
            <Separator />
            <Row>
              <Col1>email</Col1>
              <Col2>
                <input type="text" name="email" />
              </Col2>
            </Row>
            <Separator />
            <Row>
              <Col1>message</Col1>
              <Col2>
                <textarea id="contactTextarea" name="message" />
              </Col2>
            </Row>
            <Separator />
            <Row>
              <div>
                <button className="btn btn-dark" type="submit">
                  send<strong>message</strong>
                </button>
              </div>
            </Row>
          </form>
*/}
        </div>
      )}

      {message && <div>{message}</div>}
    </PaddedContent>
  );
};

export default Contact;
