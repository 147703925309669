import "./FlipCard.scss";
import { Separator } from "../layout";
import { Link } from "react-router-dom";

const FlipCard = ({ project = {} }) => {
  return (
    <>
      <div className="flip-container">
        <div className="flipper">
          <div className="front">
            <h2 className="project-title">{project.name}</h2>
            <Separator />
            {project.workedOn && (
              <div className="worked-on">
                Worked on:
                {project.workedOn.map((i) => (
                  <div className="side" key={project.name + "-" + i}>
                    {i}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="back project-card">
            <div className="project-description">
              <span>{project.description}</span>
            </div>
            <Separator />
            <div className="technologies">
              Technologies:
              {project.technologies.map((t) => (
                <div className="technology" key={project.name + "-tech-" + t}>
                  {t}
                </div>
              ))}
            </div>
            {project.more && (
              <>
                <Separator />
                <Link to={"/project/" + project.more}>
                  <button className="btn btn-dark btn-block">
                    view<strong>more</strong>
                  </button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FlipCard;
