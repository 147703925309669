import { PaddedContent, Separator } from "../layout";
import styled, { createGlobalStyle } from "styled-components";
import { detailedProjects } from "../lib/projects";
import { Link, useParams } from "react-router-dom";

const Thumbnail = styled.img`
  float: right;
  margin: 0 1em 1.5em 3em;
  max-width: 10em;
  border-radius: 8px;
`;

const ProjectTitle = styled.h1`
  color: #000000;

  :hover {
    text-decoration: underline;
  }
`;

const IsOffline = styled.div`
  padding: 4px 8px;
  border-radius: 16px;
  background-color: #b33939;
  color: #ffffff;
  font-weight: bold;
  font-size: 13px;
  display: inline;
`;

const ProjectDescription = styled.div`
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 500;
`;

const GalleryRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  overflow: auto;
  padding-bottom: 20px;
`;

const GalleryThumbnail = styled.img`
  height: 140px;
  cursor: pointer;
  filter: brightness(1);
  border-radius: 4px;

  transition: filter ease-in 0.15s;

  :hover {
    filter: brightness(0.7);
  }
`;

const GlobalStyles = createGlobalStyle`
  .main-card {
    overflow: hidden;
  }
`;

const ModalOverlay = styled.div`
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 90%;
  width: 90%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  z-index: 12;
`;

const ModalImage = styled.div`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

const ModalButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 12;
`;

const ProjectInfo = () => {
  const params = useParams();
  const project = detailedProjects[params.id];
  // const [selectedPicture, setSelectedPicture] = useState(null);

  return (
    <PaddedContent>
      <Link to="/projects">
        <ProjectTitle>
          &lt;&lt;&nbsp;
          {project.name}
        </ProjectTitle>
      </Link>
      {project.thumbnail && (
        <Thumbnail
          src={
            project.thumbnail.substr(0, 4) === "http"
              ? project.thumbnail
              : require("../" + project.thumbnail)
          }
        />
      )}
      {project.isOffline && (
        <>
          <IsOffline>currently offline</IsOffline>
          <Separator />
        </>
      )}
      <Separator />
      <ProjectDescription>{project.short}</ProjectDescription>
      <Separator />
      <p>{project.long}</p>
      <Separator />
      {project.html && (
        <div dangerouslySetInnerHTML={{ __html: project.html }} />
      )}
      <Separator />
      <h4>
        <strong>technologies</strong>
      </h4>
      {project.technologies.map((t) => (
        <span key={t} className="technology">
          {t}
        </span>
      ))}

      <Separator />
      {/*

      {project.images && project.images.length > 0 && (
        <GalleryRow>
          {project.images.map((i) => (
            <GalleryThumbnail
              src={require("../" + i.small)}
              key={i.small}
              onClick={() => setSelectedPicture(i)}
            />
          ))}
        </GalleryRow>
      )}
*/}

      {/*
      {selectedPicture && (
        <>
          <GlobalStyles />
          <ModalOverlay onClick={() => setSelectedPicture(null)} />
          <Modal>
            <ModalImage src={require("../" + selectedPicture.big)} />
            <ModalButton onClick={() => setSelectedPicture(null)}>
              close
            </ModalButton>
          </Modal>
        </>
      )}
*/}
    </PaddedContent>
  );
};

export default ProjectInfo;
