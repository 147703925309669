import styled from "styled-components";

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  margin-top: 0;
  font-family: inherit;
  line-height: 1.2;
`;

export const Subtitle = styled.h2`
  font-weight: 500;
  margin-top: 1.8rem;
  margin-bottom: 0;
  font-family: inherit;
  line-height: 1.2;
`;

export const PaddedContent = styled.div`
  padding: 2em;
  overflow: auto;
  height: 100%;
`;

export const Content = styled.p`
  font-weight: 200;
`;

export const Link = styled.a`
  font-weight: 400;
  text-decoration: none;
  color: #0099ff;

  :hover {
    text-decoration: underline;
  }
`;

export const Separator = styled.div`
  display: block;
  height: ${({ size }) => (size ? `${size}px` : "10px")};
`;

export const Small = styled.div`
  color: #777;
  font-size: 0.7em;
  padding-right: 2em;
`;
